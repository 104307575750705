import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import { CURRENT_PAGE, getEnvironment } from "./environment/useEnvironment"

const setupSentry = (): void => {
  const env = getEnvironment();
  const dsn = env[`${CURRENT_PAGE.toUpperCase()}_SENTRY_DSN`];

  if (dsn && process.env.NODE_ENV === 'production') {
    Sentry.init({
      dsn,
      integrations: [new Integrations.BrowserTracing()],
      tracesSampleRate: 1.0,
    });
  }
};

export default setupSentry;