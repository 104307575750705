import { useEffect, useState } from 'react';
import {
  devPagesEnvironments,
  MapsPageEnvironment,
  QedPageEnvironment,
  ScanformPageEnvironment
} from "./pagesEnvironments"

export type Environment = QedPageEnvironment | ScanformPageEnvironment | MapsPageEnvironment

export const isBrowser = typeof window !== 'undefined';
export const isProduction = process.env.NODE_ENV === 'production';

// eslint-disable-next-line no-underscore-dangle
declare const __PAGE__; // via define webpack plugin
export const CURRENT_PAGE: string = __PAGE__;
/*
 * Environment settings that are used before the target ones are loaded.
 */

const getDevEnvironment = () => devPagesEnvironments[CURRENT_PAGE];

const getProdEnvironment = (): Environment => ({
  ...getDevEnvironment(),
  ...(isBrowser && window.environment ? window.environment : {}),
});

export const getEnvironment =
  isProduction && isBrowser ? getProdEnvironment : getDevEnvironment;

/*
 * Environment has to be read during runtime, so we use a hook that runs once after the component
 * that uses it is mounted for the first time.
 */
function useEnvironment(): Environment | Record<string, never> {
  const [environment, setEnvironment] = useState<Environment | Record<string, never>>({});

  useEffect(
    () => {
      setEnvironment(getEnvironment);
    },
    [] /* environment does not change */,
  );

  return environment;
}

export default useEnvironment;