export interface QedPageEnvironment {
  QED_SENTRY_DSN: string
}

export interface ScanformPageEnvironment {
  SCANFORM_SENTRY_DSN: string
  SCANFORM_LOGIN_URL: string
}

export interface MapsPageEnvironment {
  MAPS_SENTRY_DSN: string
  MAPS_APP_URL: string
}

export interface ScanSpectrumPageEnvironment {
  SCANSPECTRUM_SENTRY_DSN: string
}

export interface NonprofitPageEnvironment {
  NONPROFIT_SENTRY_DSN: string
}

export interface PackPageEnvironment {
  PACK_SENTRY_DSN: string
}

export interface PagesEnvironments {
  qed: QedPageEnvironment
  scanform: ScanformPageEnvironment
  maps: MapsPageEnvironment
  scanspectrum: ScanSpectrumPageEnvironment
  scanspectrum_pl: ScanSpectrumPageEnvironment
  nonprofit: NonprofitPageEnvironment
  pack: PackPageEnvironment
}

export const devPagesEnvironments: PagesEnvironments = {
  qed: {
    QED_SENTRY_DSN: 'https://dummydsn@dummydsn.ingest.sentry.io/dummydsn'
  },
  scanform: {
    SCANFORM_SENTRY_DSN: 'https://dummydsn@dummydsn.ingest.sentry.io/dummydsn',
    SCANFORM_LOGIN_URL: 'https://example.com/login'
  },
  maps: {
    MAPS_SENTRY_DSN: 'https://dummydsn@dummydsn.ingest.sentry.io/dummydsn',
    MAPS_APP_URL: 'https://example.com'
  },
  scanspectrum: {
    SCANSPECTRUM_SENTRY_DSN: 'https://dummydsn@dummydsn.ingest.sentry.io/dummydsn'
  },
  scanspectrum_pl: {
    SCANSPECTRUM_SENTRY_DSN: 'https://dummydsn@dummydsn.ingest.sentry.io/dummydsn'
  },
  nonprofit: {
    NONPROFIT_SENTRY_DSN: 'https://dummydsn@dummydsn.ingest.sentry.io/dummydsn'
  },
  pack: {
    PACK: 'https://dummydsn@dummydsn.ingest.sentry.io/dummydsn'
  }
}